@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;600;700&display=swap');

:root {
  --initial-h1-font-size: 30px; 
  --initial-h2-font-size: 25px; 
  --initial-p-font-size: 20px; 
  --navbar-footer-color: #333333;
  --navbar-footer-font-color: #cccccc;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins', sans-serif;
  background-color: #fcfcfc;
}

.navbar-container {
  max-width: 1100px;
  margin: 0 auto;
  padding: 0 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
}

.navbar {
  height: 100px;
  background-color: var(--navbar-footer-color);
  position: relative;
}

.logo {
    cursor: pointer;
}

.menu-icon {
  display: none;
}

/* .nav-elements {
} */

.nav-elements ul {
  display: flex;
  justify-content: space-between;
  list-style-type: none;
}

.nav-elements ul li:not(:last-child) {
  margin-right: 60px;
}

.nav-elements ul a {
  font-size: 16px;
  font-weight: 400;
  color: var(--navbar-footer-font-color);
  text-transform: uppercase;
  text-decoration: none;
}

.nav-elements ul a.active {
  color: white;
  font-weight: 500;
  position: relative;
}

.nav-elements ul a.active::after {
  content: '';
  position: absolute;
  bottom: -4px;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: white;
}

@media (max-width: 768px) {
  .nav-elements ul li:not(:last-child) {
    margin-right: 30px;
  }
}

@media (max-width: 600px) {
  .menu-icon {
    display: block;
    cursor: pointer;
  }

  .nav-elements {
    position: absolute;
    right: 0;
    top: 70px;
    background-color: var(--navbar-footer-color);
    width: 0px;
    /* height: calc(100vh - 60px); */
    height: fit-content;
    transition: all 0.2s ease-in;
    overflow: hidden;
    z-index: 1;
  }

  .nav-elements.active {
    width: 65%;
  }

  .nav-elements ul {
    display: flex;
    flex-direction: column;
  }

  .nav-elements ul li {
    margin-right: unset;
    margin-top: 20px;
  }
}

.footer {
  height: 250px;
  display: flex;
  background-color: var(--navbar-footer-color);
  color: var(--navbar-footer-font-color);
  position: relative;
  align-items: center;
}

.copyright {
  text-align: center;
  flex-basis: 100%;
}

.social-icon {
  padding: 5px
}

/* novo */

.EU-note {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
  margin-bottom: 25px;
  margin-left: 25px;
  margin-right: 25px;
  border: 1px solid #ccc;
}
  
.image {
  flex: 1;
  margin: 10px;
}
  
.image img {
  max-width: 100%;
  height: auto;
}
  
.text {
  text-align: center;
  flex-basis: 100%;
}
  

h1 {
  font-size: var(--initial-h1-font-size);
  margin-bottom: 10px;
  text-align: center;
}

h2 {
  font-size: var(--initial-h2-font-size);
  margin-bottom: 10px;
  text-align: center;
}

p {
  font-size: var(--initial-p-font-size);
}

.p-footer {
  font-size: 16px;
}

.main_content {
  padding: 20px;
  margin: 20px;
  text-align: justify;
}

@media (max-width: 768px) {
  .main_content {
    padding: 5px;
    margin: 10px;
    text-align: center;
  }
}

.container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  padding: 20px;
}

.box {
  /* max-width: 500px; */
  width: 522px;
  padding: 15px;
  margin-bottom: 10px;
  margin-right: 10px;
  transition: transform 0.5s ease-in-out;
  /* z-index: -1; */
}

.activity-box {
  /* max-width: 500px; */
  width: 550px;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  transition: transform 0.5s ease-in-out;
  /* z-index: -1; */
}

.bottom-border {
  border: 1px solid var(--navbar-footer-color);
}

.spacer {
  width: 5px;
}

.box-EU {
  width: fit-content;
  padding: 10px;
  margin-bottom: 10px;
  margin-right: 10px;
  transition: transform 0.5s ease-in-out;
}

.box.left-enter {
  transform: translateX(-40%);
  /* z-index: -1; */
}

.box.right-enter {
  transform: translateX(40%);
  /* z-index: -1; */
}

/* .box.enter {
  transform: translateX(0);
} */

@media (max-width: 767px) {
  .box iframe {
    width: 100%;
    height: 100%;
  }
}

.contact {
  width: auto;
  padding: 10px;
  text-align: justify;
}

label {
  height: 30px;
  display: block;
}

input {
  width: 100%;
  height: 30px;
  font-family: 'Poppins', sans-serif;
}

textarea {
  width: 100%;
  font-family: 'Poppins', sans-serif;
}

.submit_button {
  background-color: #6ca348;
  border-style: hidden;
  font-family: 'Poppins', sans-serif;
  text-transform: uppercase;
  color: white;
  height: 25px;
}

/* stilovi za accesibility */

.accessibility-menu {
  position: fixed;
  top: 110px;
  left: 0;
  z-index: 1;
}

.accessibility-buttons {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: calc(100% + 10px); 
  left: 0;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  opacity: 0; 
  transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  padding: 10px;
}

.accessibility-buttons.open {
  visibility: visible; 
  opacity: 1; 
}

.accessibility-icon {
  position: relative; 
  cursor: pointer;
}

.accessibility-menu.open .accessibility-icon {
  z-index: 1;
}

.accessibility-buttons button {
  margin-bottom: 5px;
  background-color: #00000033;
  color: black;
  border: none;
  height: 30px;
  width: 150px;
}

.grayscale {
  filter: grayscale(100%);
}

.high-contrast {
  background-color: var(--navbar-footer-color);
  color: white;
}

/* problem uspravnih slika */
#box-image-left {
  display: flex;
  justify-content: flex-end;
};

.img-article {
  width: auto;
  height: 100%;
}

/* tablica referenci */

.table {
  width: 60%;
  margin: auto;
  border-collapse: collapse;
  margin-bottom: 20px;
  transition: opacity 1s ease-in-out;
}
td, th {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center;
}

/* galerija */
@media (max-width: 768px) {
  .gallery {
    padding: 20px;
  }
}

@media (min-width: 768px) {
  .gallery {
    padding-left: 50px;
    padding-right: 50px;
    padding-bottom: 20px;
  }
}

.lg-react-element {
  column-count: 3;
  column-gap: 10px;
}

.gallery-img {
  max-width: 100%;
  display: block;
  padding: 5px 0px;
  border-radius: 20px;
  transition: transform 0.2s;
}

.gallery-img:hover {
  filter: opacity(.9);
  transform: scale(1.01);
}